import {HashLink} from "react-router-hash-link";
import {Helmet} from "react-helmet";
import React from "react";

export const Slider = () => {
    document.title = "Certificazione EPD - Environmental Product Declaration";
    document.getElementsByTagName("meta")[3].content = "Ottieni la certificazione EPD per dimostrare l’impatto ambientale e la conformità ai CAM dei tuoi prodotti. Affidati ai nostri esperti.";
    document.getElementsByTagName("meta")[4].content = "certificazione epd - environmental product declaration - lca life cycle assessment";

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://www.certificazioneepd.it"}/>
            </Helmet>

            <div className="p-5 mb-4 bg-light rounded-3 jumb">
                <div className="container py-5 text-center">
                    <h1 className="display-5 fw-bold text-white mb-3">Certificazione EPD</h1>
                    <h3 className="text-white mb-4">Partecipa agli appalti pubblici e diventa fornitore di grandi
                        aziende</h3>
                    <p className="text-white mb-3">
                        L’EPD (Environmental Product Declaration - Dichiarazione Ambientale di Prodotto) è una
                        certificazione volontaria che ti permette di calcolare<br/> l’impatto ambientale di prodotti e
                        servizi.

                    </p>
                    <HashLink to='#contatti'>
                        <button className="btn buttonTecno">OTTIENI LA CERTIFICAZIONE EPD</button>
                    </HashLink>
                </div>
            </div>
        </>
    );
}


