import '../components/Header.css';
import {Helmet} from "react-helmet";
import React from "react";

const SliderBlog = () => {
    document.title = "Sostenibilità ambientale aziende - Le news della redazione";
    document.getElementsByTagName("meta")[3].content = "Scopri i contenuti della nostra redazione per acquisire nuove consapevolezze circa la sostenibilità ambientale, le certificazioni ambientali di prodotto e gli aggiornamenti riguardanti i CAM.";
    document.getElementsByTagName("meta")[4].content = "sostenibilità ambientale aziende, certificazioni ambientali di prodotto";

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://www.certificazioneepd.it/news"}/>
            </Helmet>

            <div className="p-5 mb-4 bg-light rounded-3 blogslider" style={{minHeight: 360}}>

            </div>
        </>
    );
}

export default SliderBlog;


