import {HashLink} from "react-router-hash-link";
import {Helmet} from "react-helmet";
import React from "react";

export const SliderPagina2 = () => {
    document.title = "Criteri Ambientali Minimi (CAM) - Certificazioni Ambientali";
    document.getElementsByTagName("meta")[4].content="criteri ambientali minimi cam, certificazioni ambientali ";
    document.getElementsByTagName("meta")[3].content="Con le certificazioni ambientali dimostri la conformità ai CAM delle tue soluzioni e diventi un fornitore della Pubblica Amministrazione.     ";

    // document.description = " Diventa un fornitore della Pubblica Amministrazione. Con le certificazioni ambientali dimostri che le tue soluzioni rispettano i CAM e ottieni l’appalto pubblico. ";
    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://www.certificazioneepd.it/cam"}/>
            </Helmet>
        <div className="p-5 mb-4 bg-light rounded-3 jumb2">
            <div className="container py-5 text-center">
                <h1 className="display-5 fw-bold text-white mb-3">Criteri Ambientali Minimi Appalti Pubblici</h1>
                <h3 className="text-white mb-4">Dimostra alla PA che le tue soluzioni sono conformi ai CAM</h3>
                <p className="text-white mb-3">
                    Per diventare un fornitore della Pubblica Amministrazione devi dimostrare che i tuoi prodotti e/o
                    servizi rispettano i Criteri Ambientali Minimi(CAM) adottati dal Ministero dell’Ambiente.

                </p>
                <p className={'text-white fw-bold mb-0'}>Vuoi aumentare il tuo punteggio in graduatoria e ottenere
                    l’appalto pubblico?</p>
                <p className={'text-white'}>Dimostra di rispettare gli standard ambientali prescritti.
                </p>
                <HashLink to='#contatti'>
                    <button className="btn buttonTecno">CONTATTACI PER SCOPRIRE COME FARE</button>
                </HashLink>
            </div>
        </div>
        </>
    );
}


