import Etichetta from '../assets/etichetta_epd.png'
import Button from "./Buttons";
import {HashLink} from 'react-router-hash-link';

export const PrimoBlocco = () => {
    return (
        <div className="row mt-5 m-top-0">
            <div className="col-xs-12 col-md-5 col-lg-5 text-center">
                <img src={Etichetta} width="450px" className="img-fluid martop-70 m-top-0" alt="Certificazione Epd"/>
            </div>
            <div className="col-xs-12 col-md-7 col-lg-7 m-top-15">
                <h2 className="fw-bolder">Perché la tua azienda ha bisogno della certificazione EPD
                </h2>
                <ul className="ulinterno">
                    <li>Soddisfi i requisiti ambientali per partecipare agli appalti pubblici</li>
                    <li>Dimostri la conformità ai CAM e accedi agli incentivi statali (es.: superbonus 110%)
                    </li>
                    <li>Ottieni i crediti per i protocolli di sostenibilità (es.: LEED V4)
                    </li>
                    <li>Vendi online nei marketplace green, come Amazon, che impongono test di sostenibilità agli
                        aspiranti venditori/produttori
                    </li>
                </ul>
                <p className="fw-bold fst-italic ">
          Il numero delle aziende italiane che consegue la certificazione EPD è
          in costante aumento.
        </p>
        <p className="">
          Nel 2021 sono 10.000 le EPD pubblicate nel mondo solo nel settore
          delle costruzioni. Dal 2018 al 2020 EPDItaly (Program operator
          nazionale) ha registrato in soli tre anni una crescita del 139%.
        </p>
        <p className="fw-bold ">Non restare indietro!</p>
        <p className="">
          <HashLink to="#contatti">
            <button className="btn buttonTecno">AFFIDATI A NOI</button>
          </HashLink>
        </p>
            </div>
        </div>

    );
}

