import '../forms.css';
import {useState,useEffect} from "react";
import axios from "axios";
import React from "react";
import {Redirect} from "react-router-dom";


export const Forms = (props) => {
    const url = "https://www.certificazioneepd.it/ip-cliente.php"
     const [ip, setIp] = React.useState(null);

    React.useEffect(() => {
        axios.get(url).then((response) => {
            setIp(response.data);

        });
    },[])





    const [inputs, setInputs] = useState({});
    const [textarea, setTextarea] = useState(
        "Messaggio"
    );

    const handleChange = (event) => {

        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
        setTextarea(value => ({...value, [event.target.name]: event.target.value}))

    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let now = new Date(Date.now());
        var obj = new Object();
        obj.timestamp=now
        obj.name = inputs.nome + ' ' + inputs.cognome
        obj.email = inputs.email
        obj.company = inputs.rag_soc
        obj.phone = inputs.telefono
        obj.message = inputs.messaggio
        obj.accept_privacy = (inputs.privacy ? true : false)
        obj['marketing-trad'] = (inputs.marketingtrad ? true : false)
        obj['marketing-auto'] = (inputs.marketingauto ? true : false)
        obj['marketing-company'] = (inputs.marketingcompany ? true : false)
        obj.ip = '0123'
        obj.piva = inputs.piva
        obj.url = "https://www.certificazioneepd.it/"
        obj.domain = "certificazioneepd.it"


       var jsonString= JSON.stringify(obj);

        axios({
            method: "POST",
            url: "https://gestione.tecnosrl.it/api/readLead.php",
            data: jsonString,
            headers: { "Content-Type": "application/json", "Accept":"application/json" },
        })
            .then(function (response) {
                //handle success
                window.location.href = 'https://www.certificazioneepd.it/grazie';

                })
            .catch(function (response) {
                //handle error
                console.log(response);
            });


    }
    return (
        <section id={'contatti'} className={'container'}>

            <div className={'row justify-content-center align-items-center'}>
                <div className={'col-xs-12 col-md-12 col-lg-12'}>
                    <h2 className={'text-center martop-50 mb-5 fw-bold formgreen'}>{props.title}</h2>
                </div>
                <div className={'col-xs-12 col-md-8 col-lg-8'}>
                    <form className={'mb-5'} onSubmit={handleSubmit}>
                        <div className={'row'}>
                            <div className={'col-xs-12 col-md-12'}>
                                <div className="row g-3">
                                    <div className="col-xs-12  col-md-4 col-lg-4">
                                        <input type="text" className="form-control" name={'nome'} placeholder="NOME*"
                                               value={inputs.nome || ""}
                                               onChange={handleChange} required/>
                                    </div>
                                    <div className="col-xs-12  col-md-4 col-lg-4">
                                        <input type="text" className="form-control" name={'cognome'}
                                               value={inputs.cognome || ""}
                                               onChange={handleChange} placeholder="COGNOME "/>
                                    </div>
                                    <div className="col-xs-12  col-md-4 col-lg-4">
                                        <input type="text" className="form-control" name={'rag_soc'}
                                               value={inputs.rag_soc || ""}
                                               onChange={handleChange} placeholder="RAGIONE SOCIALE*" required/>
                                    </div>
                                </div>
                                <div className="row g-3 m-top-10">
                                    <div className="col-xs-12 col-md-4 col-lg-4 ">
                                        <input type="text" className="form-control" name={'piva'}
                                               value={inputs.piva || ""}
                                               onChange={handleChange} placeholder="P.IVA*" required/>
                                    </div>
                                    <div className="col-xs-12  col-md-4 col-lg-4">
                                        <input type="text" className="form-control" name={'telefono'}
                                               value={inputs.telefono || ""}
                                               onChange={handleChange} placeholder="TELEFONO"/>
                                    </div>
                                    <div className="col-xs-12  col-md-4 col-lg-4">
                                        <input type="email" className="form-control" name={'email'}
                                               value={inputs.email || ""}
                                               onChange={handleChange} placeholder="E-MAIL*" required/>
                                    </div>
                                </div>
                                <div className="row g-3  m-top-10">
                                    <div className={'col-xs-12  col-md-12 col-lg-12 mb-3'}>
                                    <textarea className="form-control" name={'messaggio'}
                                              value={textarea.messaggio || ''} onChange={handleChange}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-md-10">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="gridCheck"
                                           name={'privacy'}
                                           value={'Y' || ''}
                                           onChange={handleChange} required/>
                                    <label className="form-check-label" htmlFor="gridCheck">
                                        Dichiaro di aver preso attenta visione dell'informativa e presto il consenso al
                                        trattamento dei miei dati personali per le finalità indicate al suo interno.
                                        <a href={'https://www.iubenda.com/privacy-policy/44600808'} target={'_blank'}
                                           rel="noreferrer">[Privacy
                                            Policy]</a> <a
                                        href={'https://www.iubenda.com/privacy-policy/44600808/cookie-policy'}
                                        target={'_blank'} rel="noreferrer"> [Cookie Policy]</a>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name={'marketingtrad'}
                                           value={'Y' || ''}
                                           onChange={handleChange} id="gridCheck2" required/>
                                    <label className="form-check-label" htmlFor="gridCheck2">
                                        Presto il consenso al trattamento dei miei dati per finalità di marketing
                                        mediante
                                        contatto tradizionale (telefono con operatore, posta)

                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name={'marketingauto'}
                                           value={'Y' || ''}
                                           onChange={handleChange} id="gridCheck3"/>
                                    <label className="form-check-label" htmlFor="gridCheck3">
                                        Presto il consenso al trattamento dei miei dati per finalità di marketing
                                        mediante
                                        contatto di tipo automatizzato (telefono senza operatore, sms, mms, e-mail, fax)
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name={'marketingcompany'}
                                           value={'Y' || ''}
                                           onChange={handleChange} id="gridCheck412"/>
                                    <label className="form-check-label" htmlFor="gridCheck412">
                                        Presto il consenso al trattamento dei miei dati personali alle altre società
                                        facenti
                                        parti del gruppo Tecno, per il trattamento svolto in proprio da parte di queste
                                        ultime
                                        per le medesime finalità promozionali

                                    </label>
                                </div>
                            </div>

                            <div className={'col-xs-12 col-md-2 col-lg-2'}>
                                <div className="col-xs-12 col-md-8">
                                    <p className={'mobilecenter m-top-15'}>
                                        <button type="submit" className="btn buttonTecno">CONTATTACI</button>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </section>
)
}