import Ambiente from '../assets/certificazioni-ambientali.png'


export const CamBloccoDue = () => {
    return (
        <div className="row mt-5">
            <h2 className={'fw-bolder text-center mb-5'}>Certificazioni ambientali come criteri premianti</h2>
            <div className="col-xs-12 col-md-5 col-lg-5 text-center">
                <img src={Ambiente} className="img-fluid camimg rounded" width={'80%'} alt="certificazione ambientale"/>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6 m-top-15">
                <h2 className="fw-bolder tecnoOrange">Cosa ti serve per dimostrare<br/>la conformità ai CAM
                </h2>
                <p>Per verificare se un prodotto possiede o meno le caratteristiche ambientali definite nei CAM e se
                    quindi puoi concorrere ai bandi della PA è necessario analizzare il ciclo di vita dell’elemento
                    oggetto dell’affidamento del bando.
                </p>
                <p>Ad esempio, se vuoi concorrere per ottenere la fornitura di prodotti tecnologici per la PA dovrai
                    sottoporre i tuoi prodotti all’analisi del loro impatto ambientale. Questa ti consentirà di
                    acquisire <strong>una certificazione ambientale da poter far valere nella procedura di selezione
                        come
                        criterio premiante e in tutte le future occasioni</strong> in cui dimostrare l’impegno
                    ambientale
                    dell’azienda può fare la differenza.
                </p>
                <p>
                    Le certificazioni EPD (Environmental Product Declaration), carbon footprint di prodotto ed ISO 50001
                    sono spesso incluse tra i criteri premianti dei CAM; lo stesso vale per lo studio LCA (Life Cycle
                    Assessment), procedura che sta alla base dell’EPD e della carbon footprint.
                </p>

            </div>
        </div>

    );
}

