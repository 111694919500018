export const PreForm = () => {
    return (
        <div className="row mt-5">
            <div className={'col-xs col-md-12 col-lg-12'}>
                <h2 className={'fw-bolder text-center '}>Il rispetto degli standard ambientali per uno sviluppo sostenibile è un requisito fondamentale
                </h2>
                <p className={'text-center martop-20'} style={{fontSize:'20px'}}> Non sprecare l’occasione di diventare fornitore della Pubblica Amministrazione
                </p>
            </div>
        </div>

    );
}

