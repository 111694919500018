import React, {useEffect, useState} from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { NavLink} from "react-router-dom";

;

export const Carosello = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const handleDragStart = (e) => e.preventDefault();
    let car = [];

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };
    // Nota: l'array deps vuoto [] significa
    // questo useEffect verrà eseguito una volta
    // simile a componentDidMount()
    useEffect(() => {
        fetch("https://gestione.tecnocompany.com/api/list-news.php", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                site: 'certificazioneepd',
                category: 26,
                last: ""
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Nota: è importante gestire gli errori qui
                // invece di un blocco catch() in modo da non fare passare
                // eccezioni da bug reali nei componenti.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else {
        {
            items.map(item => (
                car.push(
                    <div className={'row martop-50'}>

                        <div className={'col-md-12 col-lg-4'}>
                            <img key={item.titolo} src={item.img} width={'100%'} onDragStart={handleDragStart}
                                 role="presentation" alt={item.titolo}/>
                        </div>
                        <div className={'col-md-12 col-lg-8'}>
                            <NavLink className={'carolink'} strict  to={'/news/'+item.url}><p className={'fw-bolder carop'}>{item.titolo}</p></NavLink>
                        </div>
                    </div>
                )
            ))
        }
        return (
            <div>
                <h2 className={'martop-50 fw-bolder'}>Potrebbe interessarti:
                </h2>
                <AliceCarousel
                    mouseTracking
                    items={car}
                    responsive={responsive}
                    disableDotsControls={true}
                    controlsStrategy="alternate"
                    autoPlay={true}
                    autoPlayInterval={2000}
                    infinite={true}
                />
            </div>

        );
    }
}